import React from 'react'

import CallToAction from 'src/components/CallToAction'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

export const Content = () => (
  <ContentWrapper>
    <section className='pt-5 pb-lg-5 bg-white'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col col-lg-8'>
            <h3>Descrição dos Tipos de Risco</h3>
            <h4 className='fs-22'>I – Risco de Crédito</h4>
            <p>É a avaliação do emissor de um título de qualquer natureza honrar com seus compromissos. Inclui o caráter, a capacidade, capital, colateral e as condições dos compromissos, conhecidos com os 5 C’s do crédito.</p>
            <h4 className='fs-22'>II – Risco de Liquidez</h4>
            <p>Entende-se como risco de liquidez tanto o tempo para “zerar uma posição” no mercado, também conhecido como liquidez de um ativo, como a disponibilidade de caixa para cumprir com as obrigações, também conhecido como funding.</p>
            <h4 className='fs-22'>III – Risco Operacional</h4>
            <p>Refere-se à possibilidade de mau funcionamento do processo devido a falha, deficiência ou inadequação de fatores como humano, equipamento, sistemas, processos e eventos extremos.</p>
            <h4 className='fs-22'>IV – Risco de Mercado</h4>
            <p>Entende-se como risco de mercado as perdas provenientes de flutuações de preços de mercado incluindo, mas não limitado a, preços de commodities, taxas de juros, câmbio e preços de ações.</p>
          </div>
        </div>
      </div>
    </section>
    <div className='container pb-4'>
      <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
        <div className='position-sticky'>
          <CallToAction
            text='Abra sua conta'
            link='/pra-voce/conta-digital/pessoa-fisica/'
            section='dobra_02'
            elementAction='click button'
            elementName='Abra sua conta'
            sectionName='Tipos de Risco'
            redirectUrl='/pra-voce/conta-digital/pessoa-fisica/'
          />
        </div>
      </SideBar>
    </div>
  </ContentWrapper>
)
